import React from "react"
import styled from "styled-components"
import { Layout, Seo, Nav, Link, Button } from "components/common"

import { device } from "utils"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Nav />

    <Wrap>
      <h1>NOT FOUND</h1>

      <p>The page you requested could not be found. </p>

      <Link to="/" className="home">
        <Button>Go Home</Button>
      </Link>
    </Wrap>
  </Layout>
)

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--sp-24);
  margin-top: var(--section-mobile-nav-spacing);
  /* margin-top: 140px; */

  @media ${device.laptop} {
    margin-top: var(--section-nav-spacing);
  }

  .home {
    margin-top: var(--sp-24);
  }
`

export default NotFoundPage
